class BidInfoModel {
  constructor() {
    this.price = "";
    this.estimateFileList = []; // 견적서. 이건 한개만 올림. 즉 Object 형태로 저장해야함.
    this.proposalFileList = [];
    this.note = "";

    // 임시.
    this.currency = "";
  }

  setData(obj) {
    if (obj.price) this.price = obj.price;
    if (obj.proposalFileList) this.proposalFileList = obj.proposalFileList;
    if (obj.note) this.note = obj.note;

    if (obj.estimateFile) {
      const arr = [];
      arr.push(obj.estimateFile);

      this.estimateFileList = arr;
    }
    if (obj.currency) this.currency = obj.currency;
  }
}

export default BidInfoModel;
