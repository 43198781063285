class MyInfoModel {
  constructor() {
    this.tenderPartner = {
      corporateNum: "",
      companyName: "",
      ceo: "",
      business: "",
      businessType: "",
      address: "",
      address2: "",
      tel: "",
      fax: "",
      email: "",
      siteUrl: "",
      businessRegFileList: [],
      bankBookFileList: [],
      attachFileList: [],
    };

    // this.attachFileList = [];
  }

  setData(obj, attachFileList) {
    if (obj.corporateNum) this.tenderPartner.corporateNum = obj.corporateNum;
    if (obj.companyName) this.tenderPartner.companyName = obj.companyName;
    if (obj.ceo) this.tenderPartner.ceo = obj.ceo;
    if (obj.business) this.tenderPartner.business = obj.business;
    if (obj.businessType) this.tenderPartner.businessType = obj.businessType;
    if (obj.address) this.tenderPartner.address = obj.address;
    if (obj.address2) this.tenderPartner.address2 = obj.address2;

    if (obj.tel) this.tenderPartner.tel = obj.tel;
    if (obj.fax) this.tenderPartner.fax = obj.fax;
    if (obj.email) this.tenderPartner.email = obj.email;

    if (obj.siteUrl) this.tenderPartner.siteUrl = obj.siteUrl;

    if (obj.businessRegFile) {
      this.tenderPartner.businessRegFileList = [obj.businessRegFile];
    }
    if (obj.bankBookFile) {
      this.tenderPartner.bankBookFileList = [obj.bankBookFile];
    }
    if (obj.attachFileList) this.tenderPartner.attachFileList = obj.attachFileList;
  }
}

export default MyInfoModel;
