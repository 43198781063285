<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">입찰 관리</h2>
      </div>
      <div class="body_section">
        <AnnounceInfo :itemData="itemData.postBoard || {}" />
        <MyInfo :model="myInfo" />
        <ManagerInfo :dataProps="managerInfo" />
        <BidInfo :model="bidInfo" />
        <div class="tbl_foot">
          <p class="desc_tip">
            ※ 입찰 내역 기입 후 제출 버튼을 클릭하면, 해당 공고에 대한 입찰이 진행됩니다.
          </p>
        </div>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button
          v-if="canCancel"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickBidCancel"
        >
          입찰 취소
        </button>
        <button v-if="canModify" type="button" class="btn_primary btn_large" @click="onClickModify">
          수정
        </button>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";

import AnnounceInfo from "@/components/user/shared/AnnounceInfo";
import MyInfo from "@/components/user/bid/view/MyInfo";
import ManagerInfo from "@/components/user/bid/view/ManagerInfo";
import BidInfo from "@/components/user/bid/view/BidInfo";
import Sticky from "@/components/layout/content/Sticky";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import BidItemMixin from "@/mixins/bid/BidItemMixin";

import MyInfoModel from "@/components/user/bid/view/MyInfoModel";
import BidInfoModel from "@/components/user/bid/view/BidInfoModel";
import ManagerViewModel from "@/components/shared/managerInput/ManagerViewModel";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "BidView",
  components: {
    PageWithLayout,
    Sticky,
    AnnounceInfo,
    MyInfo,
    ManagerInfo,
    BidInfo,
  },
  mixins: [PageMixin, BidItemMixin],
  data() {
    return {
      myInfo: new MyInfoModel(),
      bidInfo: new BidInfoModel(),
    };
  },
  computed: {
    isAnnounceClosed() {
      const { postBoard } = this.itemData;

      if (!postBoard) return false;

      return postBoard.status === "C";
    },
    canCancel() {
      if (!this.itemData.postBoard) return false;
      if (this.isAnnounceClosed) return false;
      if (!this.itemData.postTender) return false;

      // 제출 완료 일때만 취소 버튼 보이면 될 듯?
      if (this.itemData.postTender.status !== "F") return false;

      return true;
    },
    canModify() {
      if (!this.itemData.postBoard) return false;
      if (this.isAnnounceClosed) return false;
      if (!this.itemData.postTender) return false;

      // 입찰한 적이 있으면 수정 불가.
      if (this.itemData.postTender.status === "F") return false;

      // 공고의 입찰진행 중일때만 수정 가능.
      // if( this.itemData.postBoard.status !== 'S' )  return false;

      return true;
    },
  },
  methods: {
    setDataFromServer(dataFromServer) {
      const {
        note,
        price,
        tenderPartner,
        tenderPersonList,
        estimateFile,
        proposalFileList,
        currency,
      } = dataFromServer.postTender;

      // 이유는 모르겠지만 attachFileList만 postTender 안에.
      this.myInfo.setData(tenderPartner);

      this.bidInfo.setData({ note, price, estimateFile, proposalFileList, currency });

      if (tenderPersonList && tenderPersonList.length > 0) this.setManagerList(tenderPersonList);
    },
    onClickGoToList() {
      this.goToList(true);
    },
    async onClickBidCancel() {
      const isSuccess = await this.cancelBid();
      if (!isSuccess) return;

      const params = {
        text: "입찰을 취소하시겠습니까?",
        onClickY: this.onClickBidCancelOk,
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickBidCancelOk() {
      this.goToList(false);
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.BID_WRITE}/${this.postCid}`,
      });
    },
  },
};
</script>
