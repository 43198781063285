<template>
  <TableViewWithTitle title="입찰정보 입력">
    <template v-slot:body>
      <tr>
        <th>투찰 금액</th>
        <td colspan="3">
          {{ countWithCurrency }}
        </td>
      </tr>
      <tr>
        <th>견적서</th>
        <td>
          <!-- 평가는 배열이 아니고 한개라고 함.. -->
          <FileView :dataList="model.estimateFileList" />
        </td>
        <th>제안서</th>
        <td>
          <FileView :dataList="model.proposalFileList" />
        </td>
      </tr>
      <tr>
        <th>비고</th>
        <td colspan="3" class="h96 td_text" v-html="noteDecoded" />
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";
import { addCommaForMoney, decodeHTMLEntities } from "@/utils/stringUtils";
import { getCountWithCurrency } from "@/utils/spUtils";

import BidInfoModel from "@/components/user/bid/view/BidInfoModel";

export default {
  name: "BidInfo",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    model: BidInfoModel,
  },
  computed: {
    countWithCurrency() {
      return getCountWithCurrency(this.model.price, this.model.currency);
    },
    noteDecoded() {
      const note = this.model.note;
      if (!note) return "";

      const result = decodeHTMLEntities(document, note);
      return result;
    },
  },
};
</script>
