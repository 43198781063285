<template>
  <TableViewWithTitle title="내 정보">
    <template v-slot:body>
      <tr>
        <th>ID (사업자 번호)</th>
        <td colspan="3">
          {{ tenderPartner.corporateNum }}
        </td>
      </tr>
      <tr>
        <th>상호</th>
        <td>{{ tenderPartner.companyName }}</td>
        <th>대표자</th>
        <td>{{ tenderPartner.ceo }}</td>
      </tr>
      <tr>
        <th>전화번호</th>
        <td>{{ tenderPartner.tel }}</td>
        <th>FAX</th>
        <td>{{ tenderPartner.fax }}</td>
      </tr>
      <tr>
        <th>주소</th>
        <td colspan="3">
          {{ addressFull }}
        </td>
      </tr>
      <tr>
        <th>대표 이메일</th>
        <td colspan="3" class="td_text">
          {{ tenderPartner.email }}
        </td>
      </tr>
      <tr>
        <th>업태</th>
        <td class="td_text">
          {{ tenderPartner.business }}
        </td>
        <th>업종</th>
        <td class="td_text">
          {{ tenderPartner.businessType }}
        </td>
      </tr>
      <tr>
        <th>사업자등록증</th>
        <td>
          <FileView :dataList="tenderPartner.businessRegFileList || []" />
        </td>
        <th>통장사본</th>
        <td>
          <FileView :dataList="tenderPartner.bankBookFileList || []" />
        </td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <td colspan="3">
          <FileView :dataList="tenderPartner.attachFileList || []" />
          <span>
          ※ 필수 첨부 파일<br/>
          회사소개서 (개요, 조직인원현황, 사업범위, 주요 레퍼런스, 제품소개 등), 재무제표 (직전 2개년), 신용평가서, 공사면허 (공사계약시 첨부)          
          </span>
        </td>
      </tr>
      <tr>
        <th>참고 URL</th>
        <td colspan="3" class="td_text">
          {{ tenderPartner.siteUrl }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";
import { getAddressTextFull } from "@/utils/spUtils";

import MyInfoModel from "@/components/user/bid/view/MyInfoModel";

export default {
  name: "MyInfo",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    model: MyInfoModel,
  },
  computed: {
    tenderPartner() {
      return this.model.tenderPartner;
    },
    addressFull() {
      const { address, address2 } = this.tenderPartner;
      const ret = getAddressTextFull(address, address2);
      return ret;
    },
  },
};
</script>
